/* 概要 —————————————————
商品：オルテコーポレーション公式
用途：コーポレートサイト
ディレクトリ：main
パターン：全ページ共通
*/

// bootstrap JS modules
import Button from 'bootstrap/js/dist/button';
import Collapse from 'bootstrap/js/dist/collapse';
import Dropdown from 'bootstrap/js/dist/dropdown';
// import Modal from 'bootstrap/js/dist/modal';
// import Offcanvas from 'bootstrap/js/dist/offcanvas';
import Tab from 'bootstrap/js/dist/tab';
// import Tooltip from 'bootstrap/js/dist/tooltip';

// その他のJS
import JS from './modules/js';
// import WFLoader from './modules/webfontloader';
import 'lazysizes';
import 'lazysizes/plugins/unveilhooks/ls.unveilhooks';
// import Loading from './modules/loading';
// import Slick from './modules/slick/slick';
// import SlickInline from './modules/slick/slick-inline';
// import VideoBtn from './modules/video-btn';
// import Gnav from './modules/gnav';

//ツールチップを初期化
const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
const tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
  //new Tooltip() でインスタンスを生成
  return new Tooltip(tooltipTriggerEl)
});

import './main/style.scss';