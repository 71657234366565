// ナビゲーションアニメーション
$(".navbar-btn").click(function () {//ボタンがクリックされたら
    $(this).toggleClass('active');//ボタン自身に activeクラスを付与し
    $("#Gnav").toggleClass('panelactive');//ナビゲーションにpanelactiveクラスを付与
    $(".gnav__circle").toggleClass('circleactive');//丸背景にcircleactiveクラスを付与
});

$("#Gnav a").click(function () {//ナビゲーションのリンクがクリックされたら
    $(".navbar-btn").removeClass('active');//ボタンの activeクラスを除去し
    $("#Gnav").removeClass('panelactive');//ナビゲーションのpanelactiveクラスを除去
    $(".gnav__circle").removeClass('circleactive');//丸背景のcircleactiveクラスを除去
});

//スクロールでheaderにクラスを付与
$(window).scroll(function () {
    if ($(window).scrollTop() > 250) {
        // $('#Header').addClass('blur');
        $('#Bg').addClass('border-trsp');
    } else {
        // $('#Header').removeClass('blur');
        $('#Bg').removeClass('border-trsp');
    }
});

// slickスライダー
// $('.slider').slick({
//     autoplay: false,//自動的に動き出すか。初期値はfalse。
//     infinite: true,//スライドをループさせるかどうか。初期値はtrue。
//     slidesToShow: 4,//スライドを画面に3枚見せる
//     slidesToScroll: 4,//1回のスクロールで3枚の写真を移動して見せる
//     prevArrow: '<div class="slick-prev"></div>',//矢印部分PreviewのHTMLを変更
//     nextArrow: '<div class="slick-next"></div>',//矢印部分NextのHTMLを変更
//     dots: true,//下部ドットナビゲーションの表示
//     responsive: [
//         {
//             breakpoint: 769,//モニターの横幅が769px以下の見せ方
//             settings: {
//                 slidesToShow: 2,//スライドを画面に2枚見せる
//                 slidesToScroll: 2,//1回のスクロールで2枚の写真を移動して見せる
//             }
//         },
//         {
//             breakpoint: 426,//モニターの横幅が426px以下の見せ方
//             settings: {
//                 slidesToShow: 1,//スライドを画面に1枚見せる
//                 slidesToScroll: 1,//1回のスクロールで1枚の写真を移動して見せる
//             }
//         }
//     ]
// });